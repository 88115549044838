var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataTable',{attrs:{"cols":_vm.cols,"path":("accounts/" + (_vm.$auth.account.id) + "/assets"),"request-params":{ with: ['servicePlans', 'assetable'] },"default-order-by":"created_at"},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var asset = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'assets.show', params: { assetId: asset.id } }}},[_vm._v(" "+_vm._s(asset.number || 'N/A')+" ")])]}},{key:"item.assetable",fn:function(ref){
var asset = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(asset.assetable.name)+" ")])]}},{key:"item.service_plans",fn:function(ref){
var asset = ref.item;
return _vm._l((asset.service_plans),function(plan){return _c('v-chip',{key:plan.pivot.id,attrs:{"small":""}},[_vm._v(" "+_vm._s(plan.name)+" ")])})}},{key:"item.last_service_date",fn:function(ref){
var asset = ref.item;
return [_vm._v(" "+_vm._s(asset.last_service_date || '-')+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }