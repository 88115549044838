<template>
  <div>
    <DataTable
      :cols="cols"
      :path="`accounts/${$auth.account.id}/assets`"
      :request-params="{ with: ['servicePlans', 'assetable'] }"
      default-order-by="created_at"
    >
      <template v-slot:item.number="{ item: asset }">
        <router-link :to="{ name: 'assets.show', params: { assetId: asset.id } }">
          {{ asset.number || 'N/A' }}
        </router-link>
      </template>
      <template v-slot:item.assetable="{ item: asset }">
        <v-chip
          small
        >
          {{ asset.assetable.name }}                  
        </v-chip>
      </template>
      <template v-slot:item.service_plans="{ item: asset }">
        <v-chip
          v-for="plan in asset.service_plans"
          :key="plan.pivot.id"
          small
        >
          {{ plan.name }}
        </v-chip>
      </template>
      <template v-slot:item.last_service_date="{ item: asset }">
        {{ asset.last_service_date || '-' }}
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'

export default {
  name: 'Assets',

  components: {
    DataTable
  },

  data () {
    return {
      cols: [
        { text: 'Asset Number', value: 'number' },
        { text: 'Account', value: 'assetable' },
        { text: 'Frame Number', value: 'frame_number' },
        { text: 'Manafacturer', value: 'manafacturer' },
        { text: 'Model', value: 'model' },
        { text: 'Service Plans', value: 'service_plans' },
        { text: 'Last Service Date', value: 'last_service_date' },
      ]
    }
  }
}
</script>
